<template>
  <div class="box">
    <div class="content">
      <div class="banner">
        <el-carousel :interval="3000" arrow="always" height="400px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <div class="title">{{ item.journalisTitle }}</div>
            <el-image class="img" :src="echoImg(item.bannerUrl)" fit="cover" @click="goDetails('', item.id)"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="list">
        <ul class="ul">
          <li class="li" :class="'li' + (index + 1)" v-for="(item, index) in subjectList" :key="index">
            <div class="liTop" :style="'background:' + color">
              <div class="liTitle">{{ item.navigationName }}</div>
              <div class="liMore" @click="more(item.navigationName, item.id, item.navigationType)">更多+</div>
            </div>
            <div class="childrenList" v-for="(dates, idx) in item.journalism" :key="idx">
              <div class="text" @click="goDetails(item.navigationName, dates.id)">
                <span class="circular"></span>
                <span class="liText">{{ dates.journalisTitle }}</span>
                <span class="liTime" v-if="index == 0">{{ toDate(dates.releaseTime, "yyyy-MM-dd") }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="clear"></div>
      <div class="picList">
        <div class="title" :style="'background:' + color">
          <span>精彩图库</span>
          <span class="more">更多+</span>
          <!--          <span class="more" @click="more(item.navigationName,item.id,item.navigationType)">更多+</span>-->
        </div>
        <el-carousel class="card" :interval="3000" type="card" height="308px">
          <el-carousel-item v-for="(item, index) in mapDepotList" :key="index">
            <div class="text">{{ item.journalisTitle }}</div>
            <el-image class="img" :src="echoImg(item.bannerUrl)" fit="cover" @click="goDetails('', item.id)"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="linksList">
        <div class="title" :style="'background:' + color">友情链接</div>
        <ul class="ul">
          <li v-for="(item, index) in linksList" :key="index">
            <el-image :src="echoImg(item.imgUrl)" @click.native="openLinks(item.linkUrl)"></el-image>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  selectLink,
  selectMapDepot,
  selectBanner,
  selectList,
} from "@/api/template";
export default {
  name: "Index",
  components: {},
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      linksList: [],
      mapDepotList: [],
      bannerList: [],
      subjectList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getSelectBanner();
      this.getSelectList();
      this.getSelectMapDepot();
      this.getSelectLink();
    },
    getSelectLink() {
      let self = this;
      selectLink().then((res) => {
        if (res.code == 200) {
          this.linksList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
    },
    getSelectBanner() {
      let self = this;
      selectBanner().then((res) => {
        if (res.code == 200) {
          this.bannerList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
    },
    getSelectMapDepot() {
      let self = this;
      selectMapDepot().then((res) => {
        if (res.code == 200) {
          this.mapDepotList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
    },
    getSelectList() {
      let self = this;
      selectList().then((res) => {
        if (res.code == 200) {
          this.subjectList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
    },
    openLinks(linkUrl) {
      window.open(linkUrl, "_blank");
    },
    more(navigationName, id, type) {
      let data = {
        name: navigationName,
        id: id,
        type: type,
      };
      window.sessionStorage.setItem("navigationValue", JSON.stringify(data));
      // this.$router.push({
      //   params: { name: navigationName, id: id },
      //   name: "first-list",
      // });
      this.$router.push({params: {name:navigationName,id:id,type: type},name:'first-list'})
    },
    goDetails(navigationName, id) {
      let data = {
        name: navigationName,
        id: id,
      };
      window.sessionStorage.setItem("detailsValue", JSON.stringify(data));
      this.$router.push("/first-details");
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 1000px;
  margin: 20px auto;
  .banner {
    width: 100%;
    height: 400px;
    .img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    .title {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      z-index: 99;
      color: #fff;
      height: 56px;
      line-height: 56px;
      padding: 0 20px;
    }
  }
  .list {
    .ul {
      //display: flex;
      //flex-flow:row wrap;
      //justify-content: space-between;
      .li {
        float: left;
        width: 320px;
        height: 292px;
        background: #fff;
        margin-left: 20px;
        margin-top: 20px;
        overflow: hidden;
        .liTop {
          padding: 0 10px;
          height: 50px;
          line-height: 50px;
          background: #1e3c5a;
          color: #fff;
          .liTitle {
            width: 80%;
            float: left;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .liMore {
            width: 20%;
            float: right;
            text-align: right;
            cursor: pointer;
          }
        }
        .childrenList {
          margin: 0 10px;
          height: 30px;
          line-height: 30px;
          .circular {
            display: inline-block;
            width: 5px;
            height: 5px;
            background: #1e3c5a;
            border-radius: 5px;
            vertical-align: middle;
            margin-right: 10px;
          }
          .liText {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 30px;
            width: 280px;
            vertical-align: middle;
            cursor: pointer;
          }
        }
      }
      .li1 {
        margin-left: 0;
        width: 660px;
        .liText {
          width: 520px !important;
        }
        .liTime {
          float: right;
        }
      }
      .li3,
      .li6,
      .li9,
      .li12,
      .li15,
      .li18,
      .li21 {
        margin-left: 0;
      }
    }
  }
  .clear {
    clear: both;
    height: 20px;
  }
  .picList {
    color: #fff;
    background: #fff;
    .title {
      height: 50px;
      line-height: 50px;
      border: 1px solid #e8e8e8;
      background-color: #1e3c5a;
      font-size: 16px;
      padding-left: 16px;
      .more {
        float: right;
        padding-right: 16px;
        cursor: pointer;
      }
    }
    .card {
      padding: 20px 0;
      .text {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 99;
        background: rgba(0, 0, 0, 0.6);
        width: 100%;
        z-index: 99;
        color: #fff;
        height: 56px;
        line-height: 56px;
        padding: 0 20px;
      }
      .img {
        width: 100%;
        height: 308px;
      }
    }
  }
  .linksList {
    margin-top: 20px;
    border: 1px solid #e8e8e8;
    .title {
      height: 50px;
      line-height: 50px;
      background: #1e3c5a;
      padding-left: 16px;
      font-size: 16px;
      color: #fff;
    }
    .ul {
      padding: 20px 10px;
      display: flex;
      //flex-flow:row wrap;
      //justify-content: space-between;
      li {
        font-size: 14px;
        //line-height: 55px;
        //height: 55px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
